/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  color: #666;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #666;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}
.fw-strong {
  font-weight: 600;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 74px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
      color: $theme-primary;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 0.9em;
      font-family: var(--font-family);
      font-weight: 600;
      line-height: 1.4em;
    }
  }
  .dropdown-list {
    clear: right;
    max-width: 300px;
    z-index: 1000;
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0.75rem 0;
    width: auto;
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      // float: right;
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
      }
    }
  }
  .dropdown-list {
    clear: right;
    float: right;
    width: 100%;
    max-width: 325px;
  }
}

#banner-wrap {
  background: linear-gradient(180deg, #b5916e 0%, #a27a50 100%);
  .details {
    h2.h1 {
      text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
    }
    ul.check {
      font-size: 1.2em;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
      li:before {
        color: $theme-primary;
      }
    }
  }
}
img.seal {
  top: 10px;
  max-width: 95px;
}
.cta-wrap {
  background: $theme-primary;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  .cta-btn {
    width: 220px;
  }
}

.yellow-center-icon {
  h3 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }
  &:hover h3 {
    border-bottom-color: inherit;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 75%;
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner {
    background: url(../images/banner-person.png) no-repeat;
    background-position: 0 bottom;
    .details {
      padding-left: 140px;
    }
  }
}
@media (min-width: 1200px) {
  #banner {
    background-position: 25px bottom;
  }
}
